$theme-colors: (
    "primary": #FCC300,
    "secondary": #00DC84,
    "success": #00DC84,
    "info": #000000,
    "warning": #FCC300,
    "danger": #E7041C,
    "light": #FFFFFF,
    "dark": #000000,
    "grey": #F8F8F8
);

$input-focus-box-shadow: none;

@import '../node_modules//bootstrap/scss/bootstrap.scss';

@font-face {
    font-family: 'FiraGO';
    src: url('./assets/fonts/FiraGO-Regular.woff2') format('woff2'),
         url('./assets/fonts/FiraGO-Regular.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FiraGO';
    src: url('./assets/fonts/FiraGO-Bold.woff2') format('woff2'),
         url('./assets/fonts/FiraGO-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

html,
body {
    font-family: 'FiraGO';
}

.text-uppercase {
    font-feature-settings: 'case' on;
}

.login-container {
    min-height: 100vh;
}

.login-l {
    background-color: #FCC300;
}

.login-box-container {
    background-color: white;
    border-radius: 1rem 1rem 0 0;
}

.grey-input,
.grey-input:focus {
    border: 0;
    outline: none;
    background-color: #F8F8F8;
    border-radius: 1rem;
    font-weight: bold;
    font-size: 1.2rem;
}

.auth-btn {
    border-radius: 1rem;
}

.menu-icon {
    position: relative;
    top: -2px;
}

.offcanvas-top {
    height: 20%;
}

.offcanvas-center {
    height: 60%;
}

.offcanvas-footer {
    height: 20%;
}

.user-btn::after {
    display: none;
}

header {
    background-color: #F8F8F8;
}

.parts-table td {
    border: 1px solid #C1C1C1;
}

.parts-table thead td {
    background-color: #00DC84;
}

.parts-table tbody td {
    background-color: #F8F8F8;
}

.parts-table tbody tr:hover > td {
    background-color: #FFF9BF;
}

.carousel-indicators {
    margin-bottom: 4rem;
}

.carousel-caption {
    margin-bottom: 4rem !important;
}

.carousel-indicators button {
    width: 16px !important;
    height: 16px !important;
    background-color: #FCC300 !important;
    border-radius: 8px !important;
    opacity: 1 !important;
    background-clip: inherit !important;
    border: 0 !important;
     transition: 0.2s;
}

.carousel-indicators button.active  {
    width: 48px !important;
}

.button-loader {
    display: inline-block;
    margin-right: 1rem;
}

.aside {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #FCC300;
    overflow-x: hidden;
    transition: 0.5s;
}

.main {
    transition: margin-left .5s;
}

.aside-close {
    position: absolute;
    top: 7rem;
    right: 0;
    border-radius: 0;
}

.aside-open {
    position: fixed;
    top: 6rem;
    left: 75px;
    border-radius: 0;
}

.tab {
    border-bottom: 1px solid #00DC84;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding: 1rem;
    text-align: center;
    font-feature-settings: 'case' on;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
}

.tab.active {
    border-bottom: 0;
    border-top: 1px solid #00DC84;
    border-left: 1px solid #00DC84;
    border-right: 1px solid #00DC84;
    border-radius: 0.375rem 0.375rem 0 0;
    background-color: #F8F8F8;
}

.tab-content {
    background-color: #F8F8F8;
    border-bottom: 1px solid #00DC84;
    border-top: 0;
    border-left: 1px solid #00DC84;
    border-right: 1px solid #00DC84;
    border-radius: 0 0 0.375rem 0.375rem;
    text-align: center;
}

.upload-inner-box {
    border: 1px dotted #00DC84;
    display: inline-block;
    padding: 1rem;
    text-align: center;
    margin: 2rem auto;
    border-radius: 0.375rem;
    background: url('./assets/images/upload-bg.svg') center center no-repeat;
    background-size: contain;
}

.table th {
    font-weight: normal;
    font-size: 0.9rem;
}

.aside-img-grow {
    width: 1.2rem;
    height: auto;
}

.part-images {
    width: 60px;
    height: auto;
}

.remove-image {
    margin-top: -0.5rem;
    margin-right: -0.5rem;
}

.part-edit {
    width: 120px;
    border-bottom: 2px solid #00DC84;
    background-color: #F8F8F8;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

.part-edit:focus {
    border-bottom: 2px solid #00DC84;
} 